import React from "react";
import { Button } from "./components/ui/button";
import { Mic } from "lucide-react";

const LandingPage = () => {
  return (
    <div className='min-h-screen bg-black text-white flex flex-col justify-center items-center'>
      <main className='text-center p-8 flex flex-col items-center'>
        <div className='mb-8'>
          <Mic className='w-10 h-10 text-blue-500 animate-pulse' />
        </div>

        <h1 className='text-4xl font-bold mb-4'>Anyone Labs</h1>

        <p className='text-xl mb-8 max-w-md mx-auto'>
          Whispering the future of voice AI.
        </p>

        <div className='space-y-4'>
          <Button
            variant='outline'
            size='lg'
            className='w-full max-w-xs border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white transition-colors duration-300'
          >
            Join the Experiment
          </Button>

          <p className='text-sm text-gray-500'>Coming soon. Are you ready?</p>
        </div>
      </main>

      <footer className='absolute bottom-4 text-center text-gray-600 text-xs'>
        <p>&copy; 2024 Anyone Labs</p>
      </footer>
    </div>
  );
};

export default LandingPage;
